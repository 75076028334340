<template>
   <div>
      <!-- Breadcrumb start -->
      <section class="bradcrumb_sec">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="bread_crumb_box mb_22">
                     <h2 class="heading_1 font_bold mb_23">Desktop Items</h2>
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item"><router-link class="navbar-brand m-0 p-0" :to="{ name: 'user_home' }">
         Home
        </router-link></li>
                           <li class="breadcrumb-item">
                              <router-link :to=" { name: 'setup_guidline' }">Setup Guidelines</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to=" { name: 'seating' }">Seating</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to=" { name: 'desk' }">Desk</router-link>
                           </li>
                           
                           <li class="breadcrumb-item active" aria-current="page">Desktop Items</li>
                           
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Breadcrumb end -->
      <!-- pagination Starts -->
      <section class="pagination_sec">
         <div class="pagination-nav">
            <div class="container-fluid custome_container">
               <div class="row justify-content-center">
                  <div class="col-md-11">
                     <div class="row justify-content-between">
                        <div class="col-md-12">
                           <nav aria-label="Page navigation example">
                              <ul class="pagination justify-content-between">
                                 <li class="page-item">
                                    <router-link :to=" { name: 'desk' }" class="page-link border-0 text_black" aria-label="Previous">
                                       <span aria-hidden="true">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="40" height="29.631" viewBox="0 0 43.575 29.631">
                                          <g id="Left_arrow" data-name="Left arrow" transform="translate(2.75 3.967)">
                                            <path id="Path_30" data-name="Path 30" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1744.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                            <path id="Path_31" data-name="Path 31" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1728.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                          </g>
                                       </svg>
                                          Previous
                                       </span>
                                    </router-link>
                                 </li>
                                 <li class="page-item">
                                    <router-link :to=" { name: 'screens' }"
                                       class="page-link border-0 text_black" aria-label="Next">
                                       <span aria-hidden="true" class="font_bold">
                                          <span class="text_primary">Up Next<span
                                                class="mobile_hide">: </span></span><span class="mobile_hide">
                                             Screens</span>
                                             <svg xmlns="http://www.w3.org/2000/svg" width="33.575" height="29.631" viewBox="0 0 33.575 29.631" style="margin-left:5px;">
                                             <g id="Right_arrow" data-name="Right arrow" transform="translate(-12884.354 20078.389)">
                                               <path id="Path_30" data-name="Path 30" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11159.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                               <path id="Path_31" data-name="Path 31" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11143.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                             </g>
                                          </svg>
                                       </span>
                                    </router-link>
                                 </li>
                              </ul>
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- pagination Ends -->
      <!-- Set Yourself up for success Starts -->
      <section class="set_yourself bg_grey pt_44 pb_50">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="image_left_side">
                  <!-- <div class="video_elements">
                      <iframe src="https://player.vimeo.com/video/661824671?h=ebd4880672&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Put Your Pants On (and other WFH Tips)"></iframe>
                   
                  </div> -->

                  <img src="../assets/images/desktop_items_banner.png" alt="img">
                  </div>
               </div>
            </div>
            <div class="row justify-content-center">
               <div class="col-md-8">
                  <div class="image_text_right_side text-center">
                     <h2 class="mt_50 mb_24">Where do I put my stuff?</h2>
                     <p class=" mb_24 p_22">
                       The placement of your desktop items has a direct impact on your posture, your comfort and your productivity. For example, if your keyboard and mouse are placed too high or low or too far from reach, you could wind up with bent back wrists, arms reaching forward and hunched and rounded shoulders. The guidelines below will help you set yourself up like a champ!
                     </p>
                  
                  </div>
               </div>
            </div>
         </div>
     <div class="text-center down_to_section newarrow">
           <a   v-scroll-to="{
     el: '#accordian_secc',
     duration: 500,
     easing: 'linear',
     offset: -350,
 }">
                              <svg xmlns="http://www.w3.org/2000/svg" width="50.132" height="35.219" viewBox="0 0 57.132 61.219">
  <g id="Down_arrow" data-name="Down arrow" transform="translate(-20020.894 -12891.354) rotate(90)">
    <path id="Path_30" data-name="Path 30" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11180.239 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
    <path id="Path_31" data-name="Path 31" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11150.357 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
  </g>
</svg>
            </a>
         </div>
      </section>
      <!-- Set Yourself up for success Ends -->
      <!-- Accordion  start -->
      <section id="accordian_secc" class="accordion_sec my_80">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xl-8">
                  <div class="accordion" id="accordionExample">
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                              <img src="../assets/images/deskto_keyboard_icon.png" alt="img">Where do I put my keyboard? 
                           </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                           data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              <div class="claim_text_box">
                              <ul class="pl_18 mb_0 text_balticsea">
                              <li><p class="mb_14">Place your keyboard at or slightly below your elbow height.</p></li>
                              <li><p class="mb_14">Keep your keyboard in front of you and as close as possible.</p></li>
                              <li><p class="mb_14">If you use a notebook or have a to-do list place them to the side of your keyboard</p></li>
                              <li><p class="mb_14">Adjust your keyboard to be as flat as possible</p></li>
                              <li><p class="mb_14">If you use a wrist rest, use it for resting in between typing.</p></li>
                              </ul>
                              </div>
                              
                           </div>
                        </div>
                     </div>
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              <img src="../assets/images/desktop_mouse_icon.png" alt="img"> Where do I put my mouse?
                           </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                           data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              <div class="claim_text_box">
                              <ul class="pl_18 mb_0 text_balticsea">
                              <li><p class="mb_14">Place your mouse on the same surface as your keyboard.</p></li>
                              <li><p class="mb_14">Keep it as close to you as possible.</p></li>
                              <li><p class="mb_14">It is best to position your mouse directly in front of your shoulder.</p></li>
                              <li><p class="mb_14">Place it on a flat surface to allow moving your mouse using your hand and 
   arm together vs. pivoting at the wrist. </p></li>
                              
                              </ul>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              <img src="../assets/images/desktop_items_icon.png" alt="img"> What about my other desktop items?
                           </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                           data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                             <p>In general, it is best to place additional, and often used, desktop items within what is called “the easy reach circle”. The more frequently used the item, the closer it should be. That said, make the effort to avoid excess clutter on your desktop as this can lead to awkward postures.</p>
                             <p>Last but not least, under the desk clutter can negatively impact posture and increase your distance from desktop items. Make sure you have adequate room for your legs and feet.</p>
                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Accordion  end -->
      <!-- more WFH tips start  -->
      <section class="more_lab_tips bg_grey pt_42">
         <div class="container">
            <div class="row justify-content-center ">
               <div class="col-md-12 col-xl-11 px_20">
                  <div class="row">
                     <div class="col-md-12">
                        <h4 class="heading_4 font_bold mb-0">More setup guidelines</h4>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'seating' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/setup_guidline/seating.png" alt="img" class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Seating</h6>
                                 <p>Ergo has your back (literally)  no matter where you sit. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'desk' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/setup_guidline/setup_guidline4.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Desk </h6>
                                 <p>Learn to set yourself up well at any desk you use.  </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'desktop_items' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/setup_guidline/setup_guidline3.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Desktop Items</h6>
                                 <p>How and what you place on your desk impacts your work style. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'screens' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/setup_guidline/setup_guidline2.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Screens</h6>
                                 <p>Wanna have great posture and be productive?  Learn how. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'accessories' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/setup_guidline/setup_guidline1.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Accessories</h6>
                                 <p>It’s all in the details.  Learn how accessories can help. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'lighting' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left"><img
                                    src="../assets/images/setup_guidline/setup_guidline6.png" alt="img"
                                    class="img-fluid"></div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Lighting</h6>
                                 <p>Learn how to use light to improve your output. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- more WFH tips end  -->


          
   </div>
</template>

<script>
   export default {
      name: "desktop_items",
      mounted(){
         document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
      },
   }
</script>